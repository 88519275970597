import * as React from 'react';
import { useRootStore } from '../../store/rootStoreProvider';
import { dateHasPassed } from '../../utils';
import { Box, BoxProps } from '../Design';
import PageTextBanner from '../PageTextBanner';
import Footer from './Footer';
import DesktopHeader from './Headers/DesktopHeader';
import MobileHeader from './Headers/MobileHeader';

interface PageLayoutProps {
  children: React.ReactNode;
  bannerInsert?: React.ReactNode | JSX.Element;
  hasHero?: boolean;
  removeBottomPadding?: boolean;
  backgroundColor?: BoxProps<'div'>['backgroundColor'];
  style?: React.CSSProperties;
}

const PageLayout = ({
  children,
  bannerInsert,
  style,
  hasHero,
  removeBottomPadding,
  backgroundColor
}: PageLayoutProps) => {
  const { announcementBar } = useRootStore();
  const { announcement, endDate, startDate } = announcementBar ?? {};
  const starDateHasPassed = startDate ? dateHasPassed(startDate) : true;
  const endDateHasPassed = endDate ? dateHasPassed(endDate) : false;

  return (
    <>
      <Box zIndex="banner" style={{ position: 'relative' }}>
        <PageTextBanner backgroundColor="primary" color="white" zIndex="base">
          {starDateHasPassed && !endDateHasPassed ? announcement : null}
        </PageTextBanner>
        <DesktopHeader />
      </Box>
      <MobileHeader />

      {bannerInsert}
      <Box
        paddingTop={hasHero ? undefined : { mobile: '32px', tablet: '40px', desktop: '48px' }}
        paddingBottom={removeBottomPadding ? undefined : { mobile: '52px', tablet: '56px', desktop: '60px' }}
        backgroundColor={backgroundColor}
        style={style}>
        {children}
      </Box>
      <Footer />
      {/* <ConsentBanner /> */}
    </>
  );
};

export default PageLayout;
