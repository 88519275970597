import { LinkProps } from 'next/link';
import { ComponentProps } from 'react';
import { useProductStore } from '../../store/rootStoreProvider';
import {
  companyName,
  KnownProductTypes,
  KnownTags,
  onlineOnlyMessage,
  Routes,
  specialRouteHrefs
} from '../../utils/const';
import BorderLeaves from '../BorderLeaves';
import { Box, Container, Flex, Stack, Text } from '../Design';
import MailingListForm from '../Forms/MailingListForm';
import LinkButton from '../LinkButton';
import Logo from '../Logo';
import InstagramLink from '../SpecialLinks/InstagramLink';

const SocialMedia = (props: ComponentProps<typeof Stack>) => (
  <Stack
    {...props}
    height="fitContent"
    minWidth="200px"
    width={{ mobile: 'third', tablet: 'auto' }}
    marginBottom={{ mobile: '40px', tablet: '0px' }}>
    <Text
      fontWeight="medium"
      textTransform="capitalize"
      color="white"
      fontSize="xl"
      lineHeight="none"
      style={{ whiteSpace: 'nowrap' }}
      role="heading"
      aria-level={3}>
      Stay Connected
    </Text>
    <Stack
      spacing={{ mobile: '0px', tablet: '12px' }}
      direction={{ mobile: 'column', tablet: 'row' }}
      textTransform="capitalize"
      justifyContent="flex-start">
      <Stack direction="row">
        <InstagramLink />
      </Stack>
    </Stack>
  </Stack>
);

export interface MobileNavColumn {
  title: string;
  navItems: {
    name: string;
    location: string | LinkProps['href'];
    onClick?: () => void;
  }[];
}

const MobileNavColumn = ({ title, navItems }: MobileNavColumn) => {
  return (
    <Stack
      minWidth={{ mobile: 'third', tablet: '150px' }}
      height="fitContent"
      width={{ mobile: 'fitContent', tablet: '150px', hd: '250px' }}
      marginBottom={{ mobile: '40px', tablet: '0px' }}>
      <Text
        fontWeight="medium"
        width="fitContent"
        textTransform="capitalize"
        color="white"
        fontSize="xl"
        textAlign="left"
        lineHeight="none"
        style={{ whiteSpace: 'nowrap' }}
        role="heading"
        aria-level={3}>
        {title}
      </Text>
      <Flex direction="column" width="full">
        {navItems?.map(({ location, name, onClick }) => (
          <LinkButton
            href={location}
            key={`footer-right-${name}`}
            buttonProps={{
              variant: 'link',
              textProps: { color: 'white' },
              textTransform: 'capitalize',
              justifyContent: 'flex-start',
              width: 'full',
              onClick: () => {
                onClick?.();
              }
            }}>
            {name}
          </LinkButton>
        ))}
      </Flex>
    </Stack>
  );
};

const Footer = () => {
  const { setFilterValues, setCategory } = useProductStore();

  const footerNavigationItems = {
    shop: [
      {
        name: 'Orchids - In Spike',
        location: specialRouteHrefs.plp({
          searchQuery: KnownTags.inSpike,
          categoryType: KnownProductTypes.orchid
        }),
        onClick: () => {
          setFilterValues([KnownTags.inSpike]);
        }
      },
      {
        name: 'Orchids - Cattleya',
        location: specialRouteHrefs.plp({
          searchQuery: KnownTags.cattleya,
          categoryType: KnownProductTypes.orchid
        }),
        onClick: () => {
          setFilterValues([KnownTags.cattleya]);
        }
      },
      { name: 'Gift Card', location: '/shop/aeribella-gift-card', onClick: () => setFilterValues(undefined) },
      {
        name: 'Shop All',
        location: specialRouteHrefs.plp({
          searchQuery: '',
          categoryType: KnownProductTypes.all
        }),
        onClick: () => {
          setFilterValues(undefined);
        }
      }
    ],
    company: [
      { name: 'Contact', location: Routes.contact },
      { name: 'Shipping & Return Policy', location: Routes.ShippingAndReturnPolicy },
      { name: 'Privacy Policy', location: Routes.privacyPolicy },
      { name: 'Terms Of Service', location: Routes.termsOfService }
    ]
  };

  return (
    <Box backgroundColor="primary" position="relative">
      <BorderLeaves />
      <Container paddingTop="60px" paddingBottom="28px">
        <Stack spacing="28px">
          <Stack flexDirection={{ mobile: 'column', tablet: 'unset' }} justifyContent="space-between">
            <Stack spacing={{ mobile: '40px', tablet: '20px' }}>
              <Stack
                spacing={{ mobile: '0px', tablet: '20px' }}
                flexDirection={{ mobile: 'row', tablet: 'row' }}
                flex={{ mobile: 'auto', desktop: '1' }}
                flexWrap="wrap"
                justifyContent={{ mobile: 'space-between', tablet: 'flex-start' }}
                alignItems="flex-start"
                flexBasis="full">
                <MobileNavColumn title="Shop" navItems={footerNavigationItems?.shop} />
                <MobileNavColumn title="Company" navItems={footerNavigationItems?.company} />
                <SocialMedia display={{ mobile: 'flex', tablet: 'none' }} />
              </Stack>
            </Stack>
            <Stack
              flex={{ desktop: '1' }}
              justify={{ mobile: 'flex-start', desktop: 'flex-end' }}
              direction={{ mobile: 'column', tablet: 'row' }}
              style={{ maxWidth: '800px' }}>
              <Flex
                display={{ mobile: 'none', desktop: 'block' }}
                height="150px"
                flex={{ tablet: '1' }}
                maxWidth="250px">
                <Logo height="full" display="flex" isIcon inverted logoWidth="200px" logoHeight="100%" />
              </Flex>
              <Flex direction="column" justifyContent="center" align="center">
                <Stack spacing="20px" direction="column" width={{ mobile: 'full', tablet: '350px' }}>
                  <SocialMedia display={{ mobile: 'none', tablet: 'flex' }} />
                  <MailingListForm
                    inputID="footer"
                    buttonColor="tertiary"
                    flipErrorColors
                    labelProps={{ color: 'white' }}
                  />
                </Stack>
              </Flex>
            </Stack>
          </Stack>
          <Stack spacing="16px">
            <Text as="p" variant="caption" color="white" textAlign={{ mobile: 'center', tablet: 'left' }}>
              {onlineOnlyMessage}
            </Text>
            <Stack
              spacing="20px"
              direction={{ mobile: 'column', tablet: 'row' }}
              alignItems={{ mobile: 'center', tablet: 'flex-end' }}
              justifyContent={{ mobile: 'center', tablet: 'space-between' }}>
              <Stack
                flex="1"
                spacing={{ mobile: '8px', tablet: '20px' }}
                direction={{ mobile: 'unset', tablet: 'row' }}
                alignItems={{ mobile: 'center', tablet: 'flex-end' }}
                justifyContent={{ mobile: 'center', tablet: 'flex-start' }}
                flexWrap="wrap"
                align="center"
                width="full">
                <Text
                  fontSize="sm"
                  color="white"
                  textAlign={{ mobile: 'center', tablet: 'left' }}
                  style={{ whiteSpace: 'nowrap' }}>
                  {`© ${new Date().getFullYear()} ${companyName}, LLC. All rights reserved.`}
                </Text>
              </Stack>
              <Text
                fontSize="sm"
                color="white"
                display="flex"
                alignItems="flex-end"
                justifyContent={{ mobile: 'center', tablet: 'flex-end' }}
                width={{ mobile: 'full', tablet: 'fitContent' }}
                minWidth={{ mobile: 'full', tablet: '350px' }}
                textAlign={{ mobile: 'center', tablet: 'left' }}>
                {'Designed & developed by Kyle Witeck'}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
