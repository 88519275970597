import { Box, BoxProps } from '../Design';
import { borderLeavesRecipe } from './styles.css';

export interface BorderLeaves extends BoxProps<'img'> {
  variant?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}

const BorderLeaves = ({ variant = 'topLeft', ...props }: BorderLeaves) => {
  const className = borderLeavesRecipe({ variant: variant });
  return (
    <Box
      as="img"
      alt=""
      loading="lazy"
      aria-hidden="true"
      src={`/assets/leaves-${variant.toLocaleLowerCase().includes('left') ? 'left' : 'right'}.webp`}
      className={className}
      display={{ mobile: 'none', tablet: 'block' }}
      // Based on ... Left: 400 / 167 .... Right: 200 / 83
      style={{
        ...props?.style,
        ...(variant.toLocaleLowerCase().includes('left')
          ? { width: '400px', height: '167px' }
          : { width: '400px', height: '166px' })
      }}
      {...props}
    />
  );
};

export default BorderLeaves;
