import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import { useProductStore } from '../../../store/rootStoreProvider';
import { NavItems, navigationItems } from '../../../utils/const';
import { Box, Button, ButtonProps, Container, Flex, Stack, Text, useDisclosure } from '../../Design';
import { ChevronDown, ChevronUp, HamburgerIcon } from '../../Design/Icons';
import Drawer from '../../Design/Starters/Drawer';
import { vars } from '../../Design/vars.css';
import SearchForm from '../../Forms/SearchForm';
import LinkButton from '../../LinkButton';
import Logo from '../../Logo';
import { headerLogo } from '../../Logo/styles.css';
import CartIconButton from './CartIconButton';
import { dropdownNavClick, mainNavClick } from './DesktopHeader';
import { mobileLogo, mobileNavButtonContainer } from './styles.css';

interface MobileHeaderProps {}

const MobileHeader = (props: MobileHeaderProps) => {
  const { onOpen, ...navDrawer } = useDisclosure();
  const { asPath, query } = useRouter();
  const { trackSearch } = useAnalytics();
  const [currentDropDownSelected, setCurrentDropDownSelected] = useState<string | undefined>(undefined);

  return (
    <>
      <Drawer {...navDrawer} scrollable>
        <Stack direction="column" marginTop="4px">
          {navigationItems?.map(({ name, location, dropdown }, idx) => {
            const sharedButtonProps: Omit<ButtonProps<'a'>, 'href'> = {
              fontSize: 'lg',
              variant: asPath === location ? undefined : 'ghosted',
              paddingTop: '4px',
              width: 'full',
              color: 'primary',
              textTransform: 'capitalize',
              justifyContent: 'flex-start',
              style: { borderRadius: 0 }
            };

            if (!dropdown) {
              return (
                <LinkButton
                  href={location}
                  key={`desktop-${name}-${idx}`}
                  buttonProps={sharedButtonProps}
                  onClick={() => trackSearch(`MobileNav: ${JSON.stringify(location)}`, 'Nav')}>
                  {name}
                </LinkButton>
              );
            }

            const isDefaultSectionHeading =
              (query?.categoryType === 'all' || Object.keys(query)?.length === 0) &&
              asPath?.toLocaleLowerCase().includes(name?.toLocaleLowerCase());

            return (
              <Box key={`desktop-${name}-${idx}`}>
                <Text
                  borderRadius="none"
                  boxShadow="none"
                  paddingTop="8px"
                  paddingBottom="8px"
                  paddingX="12px"
                  fontSize="lg"
                  lineHeight="none"
                  borderBottom="1px"
                  borderColor="border"
                  textTransform="capitalize"
                  // color="primary"
                  color={isDefaultSectionHeading ? 'white' : 'primary'}
                  backgroundColor={isDefaultSectionHeading ? 'primary' : 'white'}
                  // paddingLeft={dropDownEqualsOne ? undefined : '12px'}
                  style={{ borderRadius: '0px' }}>
                  {name}
                </Text>
                <MobileDropdown
                  key={name + '-mobile-nav' + idx}
                  navItem={{ name, location, dropdown }}
                  currentDropDownSelected={currentDropDownSelected}
                  setCurrentDropDownSelected={setCurrentDropDownSelected}
                  onNavClick={navDrawer?.onClose}
                  navDrawerIsOpen={navDrawer?.isOpen}
                  sharedDropDownButtonProps={{
                    ...sharedButtonProps
                  }}
                />
              </Box>
            );
          })}
        </Stack>
      </Drawer>
      <Box display={{ mobile: 'block', tablet: 'none' }} borderColor="border">
        <Box boxShadow="base">
          <Container>
            <Stack position="relative" align="center">
              <Flex flex="1" width="full" justifyContent="flex-end" align="center">
                <Box width="full" height="fitContent">
                  <Stack className={mobileNavButtonContainer} direction="row" align="center" paddingLeft="12px">
                    {/* Left here incase needed when adding user profile
                    <Button as="div" fontSize="md" variant="ghosted" className={mobileIconLogo}>
                      <Logo height="full" isIcon />
                    </Button> */}
                    <Logo
                      height="full"
                      style={{ marginLeft: '-12px' }}
                      logoContainerClassName={mobileLogo}
                      logoClass={headerLogo}
                    />

                    <Stack direction="row">
                      {/* TODO: add UserIconButton here (See above comment)*/}
                      <CartIconButton />
                      <Button
                        aria-label="navigation hamburger button"
                        fontSize="md"
                        variant="ghosted"
                        minWidth="48px"
                        onClick={onOpen}>
                        <HamburgerIcon style={{ width: '32px', height: '32px', color: vars.color.primary }} />
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Flex>
            </Stack>
          </Container>

          <Container paddingX="16px" paddingTop="16px" paddingBottom="12px">
            <SearchForm isMobile />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default observer(MobileHeader);

export interface MobileDropdownProps {
  navItem: NavItems | undefined;
  currentDropDownSelected: string | undefined;
  setCurrentDropDownSelected: Dispatch<SetStateAction<string | undefined>>;
  sharedDropDownButtonProps: Omit<ButtonProps<'a'>, 'href'>;
  onNavClick?: () => void;
  navDrawerIsOpen?: boolean;
}

export const MobileDropdown = ({
  navItem,
  currentDropDownSelected,
  setCurrentDropDownSelected,
  sharedDropDownButtonProps,
  onNavClick,
  navDrawerIsOpen
}: MobileDropdownProps) => {
  const { setFilterValues, clearSearchQuery } = useProductStore();
  const { asPath, query } = useRouter();
  const { trackSearch } = useAnalytics();
  const { searchQuery, categoryType } = query as { searchQuery: string | undefined; categoryType: string | undefined };
  const dropdown = navItem?.dropdown;
  const dropDownEqualsOne = dropdown?.length === 1;

  if (!dropdown) {
    return null;
  }

  return (
    <Box width="full" backgroundColor="white">
      <Stack maxWidth="full" flex="1" flexDirection="column" overflow="hidden" height="fitContent">
        <Stack width="full" marginTop="4px" spacing="4px">
          {dropdown?.map(({ catName, routes }, idx) => {
            if (typeof routes === 'string') {
              return (
                <LinkButton
                  href={routes}
                  key={`mobile-${catName}-dropdown-${idx}`}
                  buttonProps={{
                    ...sharedDropDownButtonProps,
                    variant: asPath.includes(routes) ? 'primary' : 'ghosted',
                    onClick: () => {
                      trackSearch(`MobileNav: ${JSON.stringify(routes)}`, '');
                    },
                    style: { ...sharedDropDownButtonProps?.style, borderRadius: '0px' }
                  }}>
                  {catName}
                </LinkButton>
              );
            }

            return (
              <Stack spacing="0px" flex="1" key={`mobile-${catName}-${idx}`} borderBottom="1px" borderColor="border">
                {!dropDownEqualsOne && (
                  <Button
                    variant="unstyled"
                    justifyContent="space-between"
                    borderRadius="none"
                    boxShadow="none"
                    paddingTop="4px"
                    paddingBottom="8px"
                    marginX="12px"
                    fontSize="lg"
                    color="primary"
                    lineHeight="none"
                    borderBottom={currentDropDownSelected === catName ? '1px' : undefined}
                    borderColor="border"
                    textTransform="capitalize"
                    fontWeight="bold"
                    style={{ borderRadius: '0px', paddingLeft: dropDownEqualsOne ? undefined : '16px' }}
                    onClick={() => {
                      setCurrentDropDownSelected(undefined);
                      mainNavClick({
                        currentDropDownSelected,
                        name: catName,
                        setFilterValues,
                        setCurrentDropDownSelected
                      });
                    }}>
                    {catName}
                    {currentDropDownSelected !== catName ? (
                      <ChevronDown style={{ marginLeft: '8px' }} />
                    ) : (
                      <ChevronUp style={{ marginLeft: '8px' }} />
                    )}
                  </Button>
                )}
                <Stack
                  spacing="0px"
                  overflow="hidden"
                  flex={!dropDownEqualsOne && currentDropDownSelected === catName ? '1' : 'auto'}
                  style={
                    !dropDownEqualsOne
                      ? {
                          transition: 'all 0.1s ease-in-out',
                          height: currentDropDownSelected === catName ? `${routes?.length * 44}px` : '0px'
                        }
                      : undefined
                  }>
                  {typeof routes !== 'string' && Array.isArray(routes)
                    ? routes?.map(({ name, location }, idx) => {
                        const isCurrentShopAllSelection = (() => {
                          if (typeof location === 'object' && location?.query) {
                            const hasSearchQuery: boolean =
                              searchQuery && searchQuery !== ''
                                ? !!Object.values(location?.query).includes(searchQuery) ||
                                  (location?.query?.searchQuery
                                    ?.toLocaleLowerCase()
                                    ?.includes(searchQuery?.replace(/\s/g, '-')?.toLocaleLowerCase()) &&
                                    name?.toLocaleLowerCase() === searchQuery?.toLocaleLowerCase())
                                : false;

                            const hasCategoryTypeOnly =
                              !location?.query?.searchQuery &&
                              searchQuery?.length === 0 &&
                              categoryType?.length !== 0 &&
                              categoryType !== 'all'
                                ? !!Object.values(location?.query).includes(categoryType)
                                : false;

                            if (hasSearchQuery) {
                              return true;
                            }

                            if (hasCategoryTypeOnly) {
                              return true;
                            }
                          }

                          if (typeof location === 'string' && location === asPath) {
                            return true;
                          }
                        })();

                        /* eslint react-hooks/rules-of-hooks: 0 */
                        useEffect(() => {
                          if (isCurrentShopAllSelection && currentDropDownSelected !== catName) {
                            setCurrentDropDownSelected(catName);
                          }
                        }, [isCurrentShopAllSelection, navDrawerIsOpen]);

                        return (
                          <LinkButton
                            href={location}
                            key={`mobile-${name}-dropdown-${idx}`}
                            buttonProps={{
                              ...sharedDropDownButtonProps,
                              variant: isCurrentShopAllSelection ? 'primary' : 'ghosted',
                              onClick: () => {
                                dropdownNavClick({
                                  location,
                                  searchQuery,
                                  categoryType,
                                  setCurrentDropDownSelected,
                                  clearSearchQuery,
                                  setFilterValues
                                });
                                trackSearch(`MobileNavDropdown: ${JSON.stringify(location)}`, categoryType);
                                onNavClick?.();
                              },
                              style: {
                                ...(dropDownEqualsOne
                                  ? {
                                      paddingLeft: '24px',
                                      paddingRight: '24px'
                                    }
                                  : {
                                      paddingLeft: '36px',
                                      paddingRight: '36px'
                                    }),
                                borderRadius: '0px',
                                opacity: !dropDownEqualsOne ? (currentDropDownSelected === catName ? 1 : 0) : 1
                              }
                            }}>
                            {name}
                          </LinkButton>
                        );
                      })
                    : null}
                </Stack>
              </Stack>
            );
          })}
          {dropdown?.length > 1 && !(dropdown?.length % 2 == 0) && (
            <Box flex="1" minWidth="2px" style={{ content: '""' }} />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
