import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { asLinkOnlyClass } from '../styles/index.css';
import { Button, ButtonProps } from './Design';
import { vars } from './Design/vars.css';

export interface LinkButtonProps extends Omit<LinkProps, 'passHref' | 'as'> {
  buttonProps?: Omit<ButtonProps<'a'>, 'href'>;
  children?: React.ReactNode | ((...args: any[]) => React.ReactNode);
  asLinkOnly?: boolean;
  disableAsPath?: boolean;
  includeLoader?: boolean;
  disableLinkUnderlined?: boolean;
  asLinkOnlyNoHover?: boolean;
}

const LinkButton = ({
  href,
  buttonProps,
  children,
  asLinkOnly,
  asLinkOnlyNoHover,
  disableAsPath,
  includeLoader,
  onClick,
  disableLinkUnderlined,
  ...LinkProps
}: LinkButtonProps) => {
  const { asPath } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const isOnCurrentPage =
    typeof href === 'object' && href?.pathname ? asPath.includes(href?.pathname) : asPath === href;
  const onInternalClick = (e: any) => {
    setIsLoading(true);
    onClick?.(e);
  };

  useEffect(() => {
    return () => {
      if (isLoading) {
        setIsLoading(false);
      }
    };
  });

  if (typeof href === 'string' && href.includes('http') && !asLinkOnly) {
    return (
      <Button
        as="a"
        href={isOnCurrentPage ? '' : href}
        onClick={onInternalClick}
        isLoading={includeLoader && isLoading}
        {...buttonProps}>
        {children}
      </Button>
    );
  }

  if (asLinkOnly) {
    return (
      <Link
        href={href}
        onClick={onInternalClick}
        as={typeof href === 'object' && !disableAsPath ? (href.pathname as string) : undefined}
        {...LinkProps}
        style={{
          color: asLinkOnlyNoHover ? vars.color.primary : undefined,
          textDecoration: disableLinkUnderlined ? undefined : 'underline'
        }}
        passHref
        className={asLinkOnlyNoHover ? undefined : asLinkOnlyClass}
        legacyBehavior={false}>
        {children as ReactNode}
      </Link>
    );
  }

  return (
    <Link
      legacyBehavior
      href={href}
      passHref
      as={typeof href === 'object' && !disableAsPath ? (href.pathname as string) : undefined}
      style={{ pointerEvents: buttonProps?.disabled || buttonProps?.isLoading ? 'none' : 'auto' }}
      {...LinkProps}>
      <Button
        as="a"
        onClick={onInternalClick}
        isLoading={includeLoader && isLoading}
        disabled={buttonProps?.disabled || buttonProps?.isLoading}
        style={{ pointerEvents: buttonProps?.disabled || buttonProps?.isLoading ? 'none' : 'auto' }}
        {...buttonProps}>
        {children}
      </Button>
    </Link>
  );
};

export default LinkButton;
