import { ReactNode } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Box, BoxProps, Button, Stack, Text, useDisclosure } from '../..';
import { ChevronLeft, ChevronRight } from '../../Icons';
import { vars } from '../../vars.css';
import ScreenOverlay from '../ScreenOverlay.tsx';

import { drawerRecipe, DrawerVariants } from './styles.css';

type StyleProps = {} & DrawerVariants;

export interface DrawerProps extends BoxProps<'div'>, Omit<ReturnType<typeof useDisclosure>, 'onOpen'> {
  children: ReactNode;
  variant?: StyleProps['variant'];
  title?: string;
  scrollable?: boolean;
  openLeft?: boolean;
}

const Drawer = ({ variant, isOpen, onClose, children, title, scrollable, openLeft, ...props }: DrawerProps) => {
  const sideDecider = openLeft ? { isOpenLeft: isOpen } : { isOpenRight: isOpen };
  const drawerClassName = drawerRecipe({
    variant,
    ...sideDecider
  });

  const swipeHandlers = useSwipeable(
    openLeft
      ? {
          onSwipedLeft: onClose
        }
      : {
          onSwipedRight: onClose
        }
  );

  return (
    <ScreenOverlay display={{ mobile: 'block', desktop: 'none' }} {...{ onClose, isOpen, swipeHandlers }}>
      <Box className={drawerClassName} paddingBottom="48px" {...props}>
        <Stack
          justifyContent={title ? 'space-between' : 'flex-end'}
          flexDirection={openLeft ? 'row' : 'row-reverse'}
          alignItems="center"
          backgroundColor="primary"
          paddingRight={!openLeft ? '12px' : undefined}
          paddingLeft={openLeft ? '12px' : undefined}>
          <Button
            fontSize="md"
            variant="ghosted"
            minWidth="48px"
            aria-label="Close"
            onClick={onClose}
            width="full"
            justifyContent={title ? 'space-between' : openLeft ? 'flex-end' : 'flex-start'}>
            {openLeft && title && (
              <Text as="span" fontSize="lg" color="white" textTransform="capitalize">
                {title}
              </Text>
            )}
            {openLeft ? (
              <ChevronLeft style={{ width: '24px', height: '24px', color: vars.color.white }} />
            ) : (
              <ChevronRight style={{ width: '24px', height: '24px', color: vars.color.white }} />
            )}
            {!openLeft && title && (
              <Text as="span" fontSize="lg" color="white" textTransform="capitalize">
                {title}
              </Text>
            )}
          </Button>
        </Stack>
        <Box height="full" overflowY={scrollable ? 'scroll' : undefined}>
          {children}
        </Box>
      </Box>
    </ScreenOverlay>
  );
};

export default Drawer;
