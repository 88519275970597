import { observer } from 'mobx-react';
import EmptyCart from '../../../public/assets/cart-empty.svg';
import CartFull from '../../../public/assets/cart-full.svg';
import { useCartStore } from '../../../store/rootStoreProvider';
import { Box, Text } from '../../Design';

const CartIcon = () => {
  const { cart } = useCartStore();
  const numberOfItemsInCart =
    cart?.lines
      ?.filter(({ merchandise }) => merchandise?.product?.handle !== 'heat-pack')
      ?.map(({ quantity }) => quantity)
      .reduce((partialSum, a) => partialSum + a, 0) ?? 0;

  return (
    <>
      {numberOfItemsInCart === 0 ? (
        <EmptyCart color="inherit" height="22px" />
      ) : (
        <Box position="relative">
          <CartFull color="inherit" height="22px" />
          <Text
            borderRadius="full"
            backgroundColor="white"
            position="absolute"
            fontSize="xs"
            width="16px"
            height="16px"
            border="1px"
            borderColor="gray500"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ right: -8, bottom: 3 }}>
            {numberOfItemsInCart}
          </Text>
        </Box>
      )}
    </>
  );
};

export default observer(CartIcon);
