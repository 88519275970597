import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useRef, useState } from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import { useProductStore } from '../../../store/rootStoreProvider';
import { NavItems, navigationItems } from '../../../utils/const';
import { Box, Button, ButtonProps, Container, Flex, Stack, Text } from '../../Design';
import { ChevronDown, ChevronUp } from '../../Design/Icons';
import { useOutsideClick } from '../../Design/hooks/useOutsideClick';
import SearchForm from '../../Forms/SearchForm';
import LinkButton from '../../LinkButton';
import Logo from '../../Logo';
import { headerLogo } from '../../Logo/styles.css';
import CartIconButton from './CartIconButton';
import { activePageDesktop, desktopLogo, desktopLogoContainer, navDropdownHover } from './styles.css';

const DesktopHeader = () => {
  const [currentDropDownSelected, setCurrentDropDownSelected] = useState<string | undefined>(undefined);
  const { asPath } = useRouter();
  const currentNavDropdownButton = useRef<HTMLButtonElement | null>(null);
  const { setFilterValues, category } = useProductStore();
  const { trackSearch } = useAnalytics();

  const setRef = useCallback(
    (node: any) => {
      currentNavDropdownButton.current = node;
    },
    [currentDropDownSelected]
  );

  return (
    <Box display={{ mobile: 'none', tablet: 'block' }} boxShadow="base">
      <Box>
        <Container minHeight="52px" style={{ top: '48px' }}>
          <Box position="relative">
            <Box zIndex="header" backgroundColor="white" position="relative" paddingBottom="16px">
              <Flex direction="row">
                <Box position="absolute" left="0px" zIndex="overlay" className={desktopLogoContainer}>
                  <Logo className={desktopLogo} logoClass={headerLogo} />
                </Box>
                <Box width="250px" backgroundColor="white" />
                <Flex height="full" flex="1" justifyContent="flex-end" minHeight="52px" backgroundColor="white">
                  <Stack minWidth="twoThirds">
                    <Stack spacing="4px" direction="row" justifyContent="flex-end" style={{ marginRight: '-12px' }}>
                      {navigationItems?.map(({ name, location, dropdown }) => {
                        const sharedButtonProps: Omit<ButtonProps<'a'>, 'href'> = {
                          fontSize: 'lg',
                          variant: 'ghosted',
                          paddingTop: '4px',
                          color: 'primary',
                          borderRadius: 'none',
                          textTransform: 'capitalize',
                          className: asPath === location ? activePageDesktop : undefined,
                          onClick: () => {
                            mainNavClick({
                              currentDropDownSelected,
                              name,
                              setFilterValues,
                              setCurrentDropDownSelected
                            });
                            trackSearch(`DesktopNav: ${JSON.stringify(location)}`, 'Nav');
                          }
                        };

                        const dropDownGreaterThanTwo = dropdown && dropdown?.length >= 3;

                        if (!dropdown) {
                          return (
                            <LinkButton
                              href={location}
                              key={`desktop-${name}`}
                              buttonProps={{
                                ...sharedButtonProps
                              }}>
                              {name}
                            </LinkButton>
                          );
                        }

                        const dropdownButtonOnClick = () => {
                          if (currentDropDownSelected === name) {
                            setCurrentDropDownSelected(undefined);
                          } else {
                            setCurrentDropDownSelected(name);
                          }
                        };

                        return (
                          <Box key={`desktop-${name}`} position={!dropDownGreaterThanTwo ? 'relative' : undefined}>
                            <Button
                              {...{
                                ...sharedButtonProps,
                                onClick: dropdownButtonOnClick
                              }}
                              ref={currentDropDownSelected === name ? setRef : undefined}
                              paddingRight="0px"
                              style={{ cursor: 'pointer' }}>
                              {name}
                              {currentDropDownSelected === name ? (
                                <ChevronUp style={{ marginLeft: '8px' }} />
                              ) : (
                                <ChevronDown style={{ marginLeft: '8px' }} />
                              )}
                            </Button>

                            {!dropDownGreaterThanTwo && (
                              <Dropdown
                                key={name}
                                ignoreRef={currentNavDropdownButton}
                                hasOpacityTransition
                                navItem={{ name, location, dropdown }}
                                currentDropDownSelected={currentDropDownSelected}
                                setCurrentDropDownSelected={setCurrentDropDownSelected}
                                sharedDropDownButtonProps={{
                                  paddingRight: undefined,
                                  justifyContent: 'flex-start',
                                  style: {
                                    transition: 'all 0.1s ease'
                                  },
                                  textProps: {
                                    whiteSpace: 'nowrap'
                                  },
                                  className: navDropdownHover
                                }}
                              />
                            )}
                          </Box>
                        );
                      })}
                      <Stack spacing="12px" direction="row" justifyContent="flex-end">
                        {/* TODO: add UserIconButton here*/}
                        <CartIconButton />
                      </Stack>
                    </Stack>
                    <Flex justifyContent="flex-end">
                      <SearchForm width="full" style={{ maxWidth: '650px' }} />
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Box>
            {navigationItems?.map((navigationItem) => {
              const dropDownGreaterThanTwo = navigationItem?.dropdown && navigationItem?.dropdown?.length >= 3;

              if (!dropDownGreaterThanTwo) {
                return null;
              }

              return (
                <Dropdown
                  key={navigationItem?.name + '-mega'}
                  ignoreRef={currentNavDropdownButton}
                  navItem={navigationItem}
                  keyNameExtension="-mega"
                  currentDropDownSelected={currentDropDownSelected}
                  setCurrentDropDownSelected={setCurrentDropDownSelected}
                  sharedDropDownButtonProps={{
                    paddingRight: undefined,
                    justifyContent: 'flex-start',
                    style: {
                      transition: 'all 3s ease'
                    },
                    textProps: {
                      whiteSpace: 'nowrap'
                    },
                    className: navDropdownHover
                  }}
                />
              );
            })}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default observer(DesktopHeader);

// Omit<ButtonProps<'a'>, 'href'>

export interface DropDownProps {
  navItem: NavItems | undefined;
  currentDropDownSelected: string | undefined;
  setCurrentDropDownSelected: Dispatch<SetStateAction<string | undefined>>;
  sharedDropDownButtonProps: Omit<ButtonProps<'a'>, 'href'>;
  hasOpacityTransition?: boolean;
  keyNameExtension?: string;
  ignoreRef?: MutableRefObject<HTMLElement | null>;
}

export const Dropdown = ({
  navItem,
  currentDropDownSelected,
  setCurrentDropDownSelected,
  sharedDropDownButtonProps,
  hasOpacityTransition,
  keyNameExtension = '',
  ignoreRef
}: DropDownProps) => {
  const { setFilterValues, clearSearchQuery } = useProductStore();
  const { asPath, query } = useRouter();
  const { trackSearch } = useAnalytics();
  const { searchQuery, categoryType } = query as { searchQuery: string | undefined; categoryType: string | undefined };
  const navItemName = navItem?.name;
  const dropdown = navItem?.dropdown;
  const dropDownEqualsOne = dropdown?.length === 1;
  const dropDownGreaterThanTwo = dropdown && dropdown?.length >= 3;
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(
    dropdownRef,
    () => {
      setCurrentDropDownSelected(undefined);
    },
    ignoreRef
  );

  if (!dropdown) {
    return null;
  }

  return (
    <Box
      ref={dropdownRef}
      width={!dropDownGreaterThanTwo ? 'fitContent' : 'full'}
      position={!dropDownGreaterThanTwo ? 'absolute' : 'fixed'}
      backgroundColor="white"
      boxShadow="elevated"
      zIndex="headerNav"
      left="0px"
      borderTopLeftRadius={!dropDownGreaterThanTwo ? 'base' : undefined}
      borderTopRightRadius={!dropDownGreaterThanTwo ? 'base' : undefined}
      borderBottomRightRadius="base"
      borderBottomLeftRadius="base"
      border="1px"
      borderColor="border"
      opacity={hasOpacityTransition ? (currentDropDownSelected === navItemName ? '1' : '0.5') : undefined}
      style={{
        visibility: dropDownGreaterThanTwo ? undefined : currentDropDownSelected === navItemName ? 'visible' : 'hidden',
        transform: !dropDownGreaterThanTwo
          ? undefined
          : currentDropDownSelected === navItemName
          ? 'translateY(0)'
          : 'translateY(-700px)',
        transition: hasOpacityTransition ? 'all 0.3s ease-in-out' : 'all 0.2s ease-in-out',
        top: !dropDownGreaterThanTwo ? '48px' : '148px'
      }}>
      <Stack
        maxWidth="full"
        flex="1"
        flexDirection={!dropDownGreaterThanTwo ? 'column' : 'row'}
        justifyContent={!dropDownGreaterThanTwo ? undefined : 'space-between'}
        paddingY={!dropDownGreaterThanTwo ? '8px' : '12px'}
        paddingX={!dropDownGreaterThanTwo ? undefined : { tablet: '0px', desktop: '16px' }}
        overflow="hidden"
        height="fitContent">
        <Stack
          width="full"
          flexWrap={!dropDownGreaterThanTwo ? 'nowrap' : 'wrap'}
          flexDirection={!dropDownGreaterThanTwo ? 'column' : 'unset'}
          spacing={{ tablet: '8px', desktop: '32px' }}
          style={{ rowGap: '20px' }}>
          {dropdown?.map(({ catName, routes }, idx) => {
            const subRoutStylePadding: ButtonProps<'button'>['style'] | undefined = dropDownEqualsOne
              ? undefined
              : {
                  paddingLeft: '24px',
                  paddingRight: '24px'
                };

            if (typeof routes === 'string') {
              return (
                <LinkButton
                  href={routes}
                  key={`desktop-${catName}-dropdown${keyNameExtension}-${idx}`}
                  buttonProps={{
                    ...sharedDropDownButtonProps,
                    borderBottom: idx === dropdown?.length - 1 ? undefined : '1px',
                    borderTop: idx !== dropdown?.length - 1 ? undefined : '1px',
                    borderColor: 'border',
                    variant: asPath.includes(routes) ? 'primary' : 'ghosted',
                    onClick: () => {
                      dropdownNavClick({
                        location: routes,
                        searchQuery,
                        categoryType,
                        setCurrentDropDownSelected,
                        clearSearchQuery,
                        setFilterValues
                      });
                      trackSearch(`DesktopNav: ${(JSON.stringify(routes), categoryType)}`);
                    },
                    style: { ...subRoutStylePadding, borderRadius: '0px' }
                  }}>
                  {catName}
                </LinkButton>
              );
            }

            return (
              <Stack
                spacing="0px"
                flex="1"
                maxWidth={!dropDownGreaterThanTwo ? undefined : { tablet: 'half' }}
                key={`desktop-${catName}-dropdown${keyNameExtension}-${idx}`}>
                {!dropDownEqualsOne && (
                  <Text
                    paddingTop="4px"
                    paddingBottom="8px"
                    marginX="12px"
                    fontSize="lg"
                    color="primary"
                    borderRadius="none"
                    weight="medium"
                    lineHeight="none"
                    borderBottom="1px"
                    borderColor="border"
                    textTransform="capitalize">
                    {catName}
                  </Text>
                )}
                {routes?.map(({ name, location }, idx) => {
                  const isCurrentShopAllSelection = (() => {
                    if (typeof location === 'object' && location?.query) {
                      const hasSearchQuery: boolean =
                        searchQuery && searchQuery !== ''
                          ? !!Object.values(location?.query).includes(searchQuery) ||
                            (location?.query?.searchQuery
                              ?.toLocaleLowerCase()
                              ?.includes(searchQuery?.replace(/\s/g, '-')?.toLocaleLowerCase()) &&
                              name?.toLocaleLowerCase() === searchQuery?.toLocaleLowerCase())
                          : false;

                      const hasCategoryTypeOnly =
                        !location?.query?.searchQuery &&
                        searchQuery?.length === 0 &&
                        categoryType?.length !== 0 &&
                        categoryType !== 'all'
                          ? !!Object.values(location?.query).includes(categoryType)
                          : false;

                      if (hasSearchQuery) {
                        return true;
                      }

                      if (hasCategoryTypeOnly) {
                        return true;
                      }
                    }

                    if (typeof location === 'string' && location === asPath) {
                      return true;
                    }
                  })();

                  return (
                    <LinkButton
                      disableAsPath={
                        typeof location === 'string'
                          ? location?.includes('/shop')
                          : location?.pathname?.includes('/shop')
                      }
                      href={location}
                      key={`desktop-${name}-dropdown${keyNameExtension}-${idx}`}
                      buttonProps={{
                        ...sharedDropDownButtonProps,
                        variant: isCurrentShopAllSelection ? 'primary' : 'ghosted',
                        onClick: () => {
                          dropdownNavClick({
                            location,
                            searchQuery,
                            categoryType,
                            setCurrentDropDownSelected,
                            clearSearchQuery,
                            setFilterValues
                          });
                          trackSearch(`DesktopNavDropdown: ${(JSON.stringify(location), categoryType)}`);
                        },
                        style: { ...subRoutStylePadding, borderRadius: '0px' }
                      }}>
                      {name}
                    </LinkButton>
                  );
                })}
              </Stack>
            );
          })}
          {dropdown?.length > 1 && !(dropdown?.length % 2 == 0) && (
            <Box flex="1" minWidth="2px" style={{ content: '""' }} />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

interface MainNavClickClickParams
  extends Pick<DropDownProps, 'setCurrentDropDownSelected'>,
    Pick<ReturnType<typeof useProductStore>, 'setFilterValues'>,
    Pick<DropDownProps, 'currentDropDownSelected'> {
  name?: string;
}

export const mainNavClick = ({
  currentDropDownSelected,
  name,
  setFilterValues,
  setCurrentDropDownSelected
}: MainNavClickClickParams) => {
  if (currentDropDownSelected === name) {
    return;
  }
  setFilterValues([]);
  setCurrentDropDownSelected(name);
};

interface DropdownNavClickParams
  extends Pick<DropDownProps, 'setCurrentDropDownSelected'>,
    Pick<NavItems, 'location'>,
    Pick<ReturnType<typeof useProductStore>, 'clearSearchQuery' | 'setFilterValues'> {
  searchQuery: string | undefined;
  categoryType: string | undefined;
}

export const dropdownNavClick = ({
  location,
  searchQuery,
  categoryType,
  setCurrentDropDownSelected,
  clearSearchQuery,
  setFilterValues
}: DropdownNavClickParams) => {
  if (searchQuery && categoryType) {
    clearSearchQuery();
  }

  setCurrentDropDownSelected(undefined);

  const searchFilters =
    typeof location === 'string' || location?.query?.searchQuery === '' ? [] : [location?.query?.searchQuery];

  setFilterValues(searchFilters);
};
