import { Routes } from '../../utils/const';
import { Box, Text } from '../Design';
import { InstagramIcon } from '../Design/Icons';
import LinkButton from '../LinkButton';

const InstagramLink = () => {
  return (
    <LinkButton
      href={Routes.instagram}
      buttonProps={{
        'aria-label': 'Opens new tab for Instagram Profile',
        rel: 'noopener noreferrer',
        target: '_blank',
        variant: 'link',
        textTransform: 'capitalize',
        justifyContent: 'flex-start',
        gap: '8px',
        width: 'full'
      }}>
      <Text display="flex" alignItems="center" gap="4px" fontSize="inherit" color="inherit">
        <InstagramIcon color="white" />{' '}
        <Box as="span" fontSize="inherit" color="white">
          Instagram
        </Box>
      </Text>
    </LinkButton>
  );
};

export default InstagramLink;
